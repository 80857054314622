import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { GuestGuard } from "src/auth/guard";
import CompactLayout from "src/layouts/compact";
import AuthClassicLayout from "src/layouts/auth/classic";

import { SplashScreen } from "src/components/loading-screen";

// ----------------------------------------------------------------------

// AMPLIFY
const AmplifyLoginPage = lazy(() => import("src/pages/auth/amplify/login"));
const AmplifyVerifyPage = lazy(() => import("src/pages/auth/amplify/verify"));
const AmplifyNewPasswordPage = lazy(
  () => import("src/pages/auth/amplify/new-password"),
);
const AmplifyConfirmSigninPage = lazy(
  () => import("src/pages/auth/amplify/confirm-signin"),
);
const TOTPCode = lazy(
  () => import("src/pages/auth/amplify/totp-code"),
);
const AmplifyForgotPasswordPage = lazy(
  () => import("src/pages/auth/amplify/forgot-password"),
);

// ----------------------------------------------------------------------

const authAmplify = {
  path: "",
  element: (
    <Suspense fallback={<SplashScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: "login",
      element: (
        <GuestGuard>
          <AuthClassicLayout>
            <AmplifyLoginPage />
          </AuthClassicLayout>
        </GuestGuard>
      ),
    },
    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        { path: "verify", element: <AmplifyVerifyPage /> },
        { path: "new-password", element: <AmplifyNewPasswordPage /> },
        { path: "forgot-password", element: <AmplifyForgotPasswordPage /> },
        { path: "first-access", element: <AmplifyConfirmSigninPage /> },
        { path: "totp-code", element: <TOTPCode /> },
      ],
    },
  ],
};

export const authRoutes = [
  {
    path: "auth",
    children: [authAmplify],
  },
];
