import {useMemo, useState, useEffect} from 'react';

import {paths} from 'src/routes/paths';

import {useTranslate} from 'src/locales';

import SvgColor from 'src/components/svg-color';

import Iconify from "../../components/iconify";
import {useAuthContext} from "../../auth/hooks";
import EmployeeService from "../../services/EmployeeService";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  timesheet: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const {t} = useTranslate();
  const {user} = useAuthContext();

  const [links, setLinks] = useState([]);

  const fetchLinks = async () => {
    const res = await EmployeeService.getExternalLinks();
    setLinks(res.data);
  }

  const MENU_ITEMS = {
    notices: {
      title: t('notices'),
      path: paths.dashboard.notices,
      icon: ICONS.mail,
    },
    calendar: {
      title: t('calendar'),
      path: paths.dashboard.root,
      icon: ICONS.calendar,
    },
    richieste: {
      title: t('richieste'),
      path: paths.dashboard.requests,
      icon: ICONS.booking,
    },
    clocking: {
      title: t('clocking'),
      path: paths.dashboard.clocking,
      icon: ICONS.job,
    },
    expense_reports: {
      title: t('expense_reports'),
      path: paths.dashboard.expenseReports,
      icon: ICONS.invoice,
    },
    documents: {
      title: t('documents'),
      path: paths.dashboard.documents,
      icon: ICONS.file,
    },
    dati_contabili: {
      title: t('dati contabili'),
      path: paths.dashboard.payrollData,
      icon: <Iconify icon="solar:calculator-bold-duotone" sx={{width: 24, height: 24}} />,
    }
  }

  const bottom_menu_items = {
    clocking: [MENU_ITEMS.notices, MENU_ITEMS.calendar, MENU_ITEMS.clocking, MENU_ITEMS.richieste, MENU_ITEMS.documents],
    precompiled_employee: [MENU_ITEMS.notices, MENU_ITEMS.calendar, MENU_ITEMS.richieste, MENU_ITEMS.expense_reports, MENU_ITEMS.documents],
    precompiled_company: [MENU_ITEMS.notices, MENU_ITEMS.calendar, MENU_ITEMS.richieste, MENU_ITEMS.expense_reports, MENU_ITEMS.documents],
    precompiled_employee_clocking: [MENU_ITEMS.notices, MENU_ITEMS.calendar, MENU_ITEMS.clocking, MENU_ITEMS.richieste, MENU_ITEMS.documents],
    precompiled_company_clocking: [MENU_ITEMS.notices, MENU_ITEMS.calendar, MENU_ITEMS.richieste, MENU_ITEMS.clocking, MENU_ITEMS.documents],
    manual: [MENU_ITEMS.notices, MENU_ITEMS.calendar, MENU_ITEMS.richieste, MENU_ITEMS.expense_reports, MENU_ITEMS.documents],
  }

  const drawer_menu_items = {
    clocking: [MENU_ITEMS.expense_reports, MENU_ITEMS.dati_contabili],
    precompiled_employee: [MENU_ITEMS.dati_contabili],
    precompiled_company: [MENU_ITEMS.dati_contabili],
    precompiled_employee_clocking: [MENU_ITEMS.expense_reports, MENU_ITEMS.dati_contabili],
    precompiled_company_clocking: [MENU_ITEMS.expense_reports, MENU_ITEMS.dati_contabili],
    manual: [MENU_ITEMS.dati_contabili],
  }

  useEffect(() => {
    if (user) {
      fetchLinks().then()
    }
  }, [user]);

  const data = useMemo(
    () => [
      // DESKTOP
      // ----------------------------------------------------------------------
      {
        items: [
          ...(user && user.data && user.data?.timesheet_type && bottom_menu_items[user?.data?.timesheet_type]
              ? bottom_menu_items[user.data.timesheet_type].filter(
                (item) => user.data?.company?.feature_expense_reports || item.title !== t('expense_reports'))
              : []
          ),
          // {
          //   title: t('notices'),
          //   path: paths.dashboard.notices,
          //   icon: ICONS.mail,
          // },
          // ...(user && user.data && (TYPES_WITH_CALENDAR.includes(user.data?.company?.timesheet_type)) ? [{
          //   title: t('calendar'),
          //   path: paths.dashboard.root,
          //   icon: ICONS.calendar,
          // }] : []),
          // ...(user && user.data && (!TYPES_WITH_CALENDAR.includes(user.data?.company?.timesheet_type)) ? [{
          //   title: t('richieste'),
          //   path: paths.dashboard.requests,
          //   icon: ICONS.calendar,
          // }] : []),
          // ...(user && user.data && (TYPES_WITH_CLOCKING.includes(user.data?.company?.timesheet_type)) ? [{
          //   title: t('clocking'),
          //   path: paths.dashboard.clocking,
          //   icon: ICONS.job,
          // }] : []),
          // ...(user && user.data && user.data?.company?.feature_expense_reports ? [{
          //   title: t('expense_reports'),
          //   path: paths.dashboard.expenseReports,
          //   icon: ICONS.invoice,
          // }] : []),
          // {
          //   title: t('documents'),
          //   path: paths.dashboard.documents,
          //   icon: ICONS.file,
          // },
        ],
      },

      // PROFILE
      // ----------------------------------------------------------------------
      {
        subheader: t('profile'),
        items: [
          ...(user && user.data && user.data?.timesheet_type && drawer_menu_items[user?.data?.timesheet_type]
              ? drawer_menu_items[user?.data?.timesheet_type].filter(
                (item) => user.data?.company?.feature_expense_reports || item.title !== t('expense_reports'))
              : []
          ),
          ...(user && links.length > 0 ? [{
            title: t('external_services'),
            path: '#/external',
            icon: ICONS.external,
            children: links.map((link) => (
              {
                title: link.description,
                path: link.url.startsWith('http') ? link.url : `https://${link.url}`,
                externalLink: true,
              }
            )),
          }] : []),
          // {
          //   title: t('prospetto mensile'),
          //   path: paths.dashboard.monthlyTimesheet,
          //   icon: ICONS.analytics,
          // },
        ]
      },

      // COMPANY
      // ----------------------------------------------------------------------
      ...(user && user.groups && (user.groups?.includes('Azienda') || user.groups?.includes('Responsabile')) ? [{
        subheader: t('company'),
        items: [
          {
            title: t('approve requests'),
            path: paths.dashboard.approveRequests,
            icon: <Iconify icon="solar:calendar-search-bold-duotone"/>,
          },
        ]
      }] : []),


    ],
    [t, user, links]
  );

  return data;
}
